import mapVerify from '@/utils/formValidate'

// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 参数分组
    {
      prop: 'configGroupName',
      label: that.$t('lang.gles.base.configGroup'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 字典名称
    {
      prop: 'configName',
      label: that.$t('lang.gles.base.configName'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 参数值
    {
      prop: 'configValue',
      label: that.$t('lang.gles.base.configValue'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 描述
    {
      prop: 'description',
      // label: that.$t('lang.gles.base.systemParamDescription'),
      label: that.$t('描述'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 状态
    {
      prop: 'configStatus',
      label: `${that.$t('lang.gles.base.usableFlag')}`,
      isShow: true,
      showOverflowTooltip: true,
      slotName: 'configStatus',
      minWidth: 78
    },
    // 创建人
    {
      prop: 'createUser',
      label: that.$t('lang.gles.common.createUser'),
      isShow: true
    },
    // 创建时间
    {
      prop: 'createTime',
      label: that.$t('lang.gles.common.createTime'),
      isShow: true,
      minWidth: 180
    },
    // 编辑人
    {
      prop: 'updateUser',
      label: that.$t('lang.gles.common.updateUser'),
      isShow: true
    },
    // 编辑时间
    {
      prop: 'updateTime',
      label: that.$t('lang.gles.common.updateTime'),
      isShow: true,
      minWidth: 180
    }
  ]
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 参数分组
    {
      name: 'configGroup',
      value: row.configGroup || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.configGroup'),
      rules: mapVerify(['required']),
      data: that.systemParamGroupList,
      disabled: that.mode === 'edit'
    },
    // 参数名称
    {
      name: 'configName',
      value: row.configName || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.configName'),
      rules: mapVerify(['required', 'inputName']),
      disabled: that.mode === 'edit'
    },
    // 参数值
    {
      name: 'configValue',
      value: row.configValue || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.configValue'),
      rules: mapVerify(['inputLen200'])
    },
    // 描述
    {
      name: 'description',
      value: row.description || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      // label: that.$t('lang.gles.base.systemParamDescription'),
      label: that.$t('描述'),
      rules: mapVerify(['inputLen200']),
      disabled: that.mode === 'edit'
    },
    // 参数状态
    {
      name: 'configStatus',
      value: row.configStatus ?? 0,
      span: 24,
      component: 'elSwitch',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.usableFlag'),
      activeValue: 0,
      inactiveValue: 1,
      activeText: that.$t('lang.gles.common.enable'),
      inactiveText: that.$t('lang.gles.common.disable'),
      class: 'switch',
      disabled: true
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return formData
}

<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :is-show-more-search="false"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
      @baseSearch="handleBaseSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <!-- <el-button type="primary" icon="el-icon-plus" @click="updateCom"> {{ $t('lang.gles.common.addNew') }} </el-button> -->
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @pageChange="pageChange"
      >
        <template #configStatus="{ row }">
          <el-switch
            v-model="row.configStatus"
            class="switch"
            :active-text="$t('lang.gles.common.enable')"
            :inactive-text="$t('lang.gles.common.disable')"
            :active-value="0"
            :inactive-value="1"
            disabled
          />
        </template>
      </m-table>
    </el-card>
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getSearchTableItem } from '../data'
import listMixins from '@/mixins/listMixins'
import QueryView from '@/components/moreQuery/queryView.vue'
import { mapState } from 'vuex'

export default {
  name: 'FactoryList',
  components: {
    ColumnConfig,
    MTable,
    QueryView
  },
  mixins: [listMixins],
  data() {
    return {
      tableData: [],
      baseFormModel: {
        codeOrName: ''
      },
      formModel: {},
      tableExtendConfig: {
        sortNum: true,
        checkBox: false,
        operateWidth: '60px',
        operate: [
          {
            event: 'editItem',
            label: this.$t('lang.gles.common.edit')
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('base', ['systemParamGroupList']),
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed
      }
      return getSearchTableItem(this, {}, methods)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    if (!this.systemParamGroupList?.length) {
      this.$store.dispatch('base/getSystemParamGroupList')
    }
    this.doSearch(this.formModel)
  },
  methods: {
    // 查询
    async doSearch(params, api = 'querySystemConfigByCodeOrNamePage') {
      const { data, code } = await this.$httpService(
        this.$apiStore.base(api, this.pageData),
        params
      )
      if (code) return
      const {
        recordList = [],
        currentPage,
        pageSize,
        totalRecordCount: recordCount
      } = data || {}
      this.pageData = { currentPage, pageSize, recordCount }
      recordList?.forEach((item) => {
        item.configGroupName =
          this.systemParamGroupList.find((t) => t.value === item.configGroup)
            ?.label ?? ''
      })
      this.tableData = recordList || []
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 启用/禁用数据字典
     */
    handleChangeConfigStatus(row) {
      const confirmMsg = this.$t('确定启用/禁用？')
      this.$confirm(confirmMsg, '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(() => {
        this.updateDictStatus(row)
      })
      return
    },
    /**
     * 接口更新字典启用/禁用状态
     */
    async updateDictStatus(row) {
      const { code } = await this.$httpService(
        this.$apiStore.base('changeConfigStatus'),
        {
          id: row.id,
          detailStatus: row.detailStatus ? 0 : 1
        }
      )
      if (code) return
      this.doSearch(this.baseFormModel)
    },
    updateCom() {
      this.$emit('updateCom', {
        currentCom: 'SystemConfigAdd',
        mode: 'add',
        row: {}
      })
    },
    /**
     * 编辑
     */
    editItem({ row }) {
      this.$emit('updateCom', {
        currentCom: 'SystemConfigAdd',
        mode: 'edit',
        row
      })
    }
  }
}
</script>

<template>
  <div class="container-type">
    <component
      :is="currentCom"
      :mode="mode"
      :row-detail.sync="row"
      @updateCom="updateCom"
      @update:row-detail="rowDetail = $event"
    />
  </div>
</template>
<script>
import SystemConfigList from './components/list.vue'
import SystemConfigAdd from './components/add.vue'
export default {
  components: {
    SystemConfigList,
    SystemConfigAdd
  },
  data() {
    return {
      currentCom: 'SystemConfigList',
      mode: '',
      row: {}
    }
  },
  methods: {
    updateCom({ currentCom, mode, row = {}}) {
      this.currentCom = currentCom
      this.mode = mode
      this.row = row
    }
  }
}
</script>
<style lang="scss" scoped></style>

<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <el-card class="add-card">
      <h3 class="title">{{ $t('lang.gles.common.basicInfo') }}</h3>
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="120"
        :extend-config="extendFormConfig"
        :button="button"
        class="basic-data-add-form"
        @submit="handleSubmit"
        @reset="handleCancel"
      />
    </el-card>
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import { getEditBaseFormData, getDetailTableItem } from '../data'
import addMixins from '@/mixins/addMixins'
import { mapState } from 'vuex'

export default {
  name: 'BaseDictAdd',
  components: {
    MForm,
    HeaderPath
  },
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tableExtendConfig: {
        sortNum: true
      },
      tableData: [],
      pageData: {
        currentPage: 1,
        pageSize: 10
      }
    }
  },
  computed: {
    ...mapState('base', ['systemParamGroupList']),
    headerPaths() {
      return this.mode === 'add'
        ? [this.$t('lang.gles.base.configAdd')]
        : [this.$t('lang.gles.base.configEdit')]
    },
    baseFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed
      }
      return getDetailTableItem(this, {}, methods)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  watch: {
    rowDetail() {
      this.formModel = { ...this.rowDetail }
    }
  },
  created() {
    if (!this.systemParamGroupList?.length) {
      this.$store.dispatch('base/getSystemParamGroupList')
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.myForm.getValidateFormModel().then(async (model) => {
        this.mode === 'add'
          ? this.insert({ ...this.rowDetail })
          : this.update({ ...this.rowDetail })
      })
    },
    /**
     * 新增系统参数
     * @param {*} params
     */
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('addSystemConfig'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.handleCancel()
    },
    /**
     * 新增系统参数
     * @param {*} params
     */
    async update(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('updateSystemConfig'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.handleCancel()
    },
    handleCancel() {
      this.$emit('updateCom', {
        currentCom: 'SystemConfigList',
        mode: ''
      })
    }
  }
}
</script>
